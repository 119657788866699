exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bespoke-custom-js": () => import("./../../../src/pages/bespoke-custom.js" /* webpackChunkName: "component---src-pages-bespoke-custom-js" */),
  "component---src-pages-coffee-tables-js": () => import("./../../../src/pages/coffee-tables.js" /* webpackChunkName: "component---src-pages-coffee-tables-js" */),
  "component---src-pages-consoles-js": () => import("./../../../src/pages/consoles.js" /* webpackChunkName: "component---src-pages-consoles-js" */),
  "component---src-pages-handmade-js": () => import("./../../../src/pages/handmade.js" /* webpackChunkName: "component---src-pages-handmade-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lamps-js": () => import("./../../../src/pages/lamps.js" /* webpackChunkName: "component---src-pages-lamps-js" */),
  "component---src-pages-mirrors-js": () => import("./../../../src/pages/mirrors.js" /* webpackChunkName: "component---src-pages-mirrors-js" */),
  "component---src-pages-tables-js": () => import("./../../../src/pages/tables.js" /* webpackChunkName: "component---src-pages-tables-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

